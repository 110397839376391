import React from 'react'
import { useState } from 'react';
import MainLayout from '../layout/main-layout';
import HeroText from '../components/hero-text'
import EmailSignup from '../components/email-signup';
import styles from './contact.module.scss';


const Contact = () => {

    const [status, setStatus] = useState();

    const onSubmit = async (e) => {
        e.preventDefault();
        const target = e.target;
        
        const data = new FormData(e.target);
        setStatus('PENDING');
        
        const response = await fetch('https://formspree.io/f/mgepzgbr', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: data
        });
        const json = await response.json();
        
        if(json.ok) {
            setStatus('SUCCESS');
            target.reset();
            return;
        }

        setStatus('ERROR');
    }

    return (
        <MainLayout>
            <section className={styles.hero}>
                <div className="container mb-12 lg:mb-20 md:flex justify-between">
                    <HeroText>
                        <h1>Get in touch.</h1>
                        <p>Want to join our mailing list? Questions? Interested in a collaboration or being a clinical trial investigator? Let's talk!</p>
                        <p>Invirsa takes a collaborative approach to working through complicated scientific problems, so we want to hear from you.</p>
                        <p className="border-t-1">Get updates on Invirsa developments:</p>
                        <EmailSignup></EmailSignup>
                    </HeroText>
                    <form onSubmit={onSubmit} className={styles.form}>
                        <p>Fill out the form below and we'll be in touch shortly!</p>
                        <div>
                            <label htmlFor="email" >Email</label>
                            <input type="email" name="email" required/>
                        </div>
                        <div>
                            <label htmlFor="subject">Subject</label>
                            <input type="text" name="subject" required/>
                        </div>
                        <div>
                            <label htmlFor="message">Message</label>
                            <textarea name="message" cols="30" rows="10" required></textarea>
                        </div>
                        <button type="submit" className="button">Submit</button>
                        {status && <div>
                            {status === 'PENDING' && <p>Sending...</p>}
                            {status === 'SUCCESS' && <p>Thanks for reaching out! We'll be in touch soon.</p>}
                            {status === 'ERROR' && <p>Oops! There was an error. Try again or email us at info@invirsa.com</p>}
                        </div>}
                    </form>
                </div>
                <div className="container">
                    
                </div>
            </section>
        </MainLayout>
    )
}

export default Contact